import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { usePapaParse } from "react-papaparse";
import { MenuProps, Input, Space } from "antd";
import { Breadcrumb, Button, Layout, Menu, theme } from "antd";
// import VocabList from "./vocabList";
// import FlashCardModal from "./Modal";
import type { SearchProps } from "antd/es/input/Search";
import { AudioOutlined } from "@ant-design/icons";
import VocabList from "../organism/vocabList";
import { useC1Store, useC2Store, useStore } from "../../state/stores/store";
import { Category } from "../../consts/category.type";
import { getWordsByCategory } from "../../api/axios/words";
import InfoModal from "../molecule/infoModal";
const { Search } = Input;
// const { Search } = Input;

const { Header, Content, Sider } = Layout;

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1677ff",
    }}
  />
);

const testData = require("../../testData.json");
// const c2json = require("./c2.json");
// const jsonTest = require("./c1.json");

const AllButton = styled(Button)`
  margin-right: 8px;
`;

const Logo = styled.span`
  font-family: "Seymour One", sans-serif;
  font-style: bold;
  font-size: 40px;
`;

const MenuItemLabelText = styled.span``;

const items1: MenuProps["items"] = ["Home", "Test"].map((key) => ({
  key,
  label: key,
}));

const Home: React.FC = () => {
  const state = useStore((state) => state);
  const c1 = useC1Store((state) => state.category);
  const c2 = useC2Store((state) => state.category);
  const updateWords = useStore((state) => state.updateWords);
  const breadcrumbs = useStore((state) => state.breadcrumbs);
  const setBreadcrumbs = useStore((state) => state.setBreadcrumbs);
  const setDisplayableWords = useStore((state) => state.setDisplayedWords);
  const loadedIds = useStore((state) => state.loadedIds);
  // const updateWords = useWordsStore((state) => state.setWords);

  const { readString } = usePapaParse();

  // const setWords =
  // useWordsStore.set

  const [search, setSearch] = useState("");

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    // console.log(value);
    setSearch(value);
    filterBySearch();
  };

  const filterBySearch = () => {
    // return
    const filteredWords = words.filter((wordRow: any) => {
      //   console.log({ wordRow });
      if (!wordRow.join(" ").includes(search)) return false;
      //   console.log({ wordRow });
      wordRow[1] = (wordRow[1] ?? "").toLowerCase();
      wordRow[2] = (wordRow[2] ?? "").toLowerCase();
      wordRow[3] = (wordRow[3] ?? "").toLowerCase();
      for (let bc of breadcrumbs) {
        bc = bc.toLowerCase();
        if (!wordRow.includes(bc)) {
          return false;
        }
      }
      return wordRow;
    });
  };

  const [categories, setCategories] = useState() as any;
  // const [breadcrumbs, setBreadcrumbs] = useState(["aa", "vv", "dd"]);
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const [displayableTableWords, setDisplableTableWords] = useState([]) as any;
  const [words, setWords] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const setLoading = useStore((state) => state.setLoading);

  const filterWords = () => {
    const filteredWords = words.filter((wordRow: any) => {
      //   console.log({ wordRow });
      wordRow[1] = (wordRow[1] ?? "").toLowerCase();
      wordRow[2] = (wordRow[2] ?? "").toLowerCase();
      wordRow[3] = (wordRow[3] ?? "").toLowerCase();
      for (let bc of breadcrumbs) {
        bc = bc.toLowerCase();
        if (!wordRow.includes(bc)) {
          return false;
        }
      }
      return wordRow;
    });
    // console.log({ filteredWords });
    return filteredWords;
    setDisplayableWords(filteredWords);
  };

  // ON BREADCRUMB UPDATE
  useEffect(() => {
    setLoading(true);
    // FETCH NEW WORDS FROM API
    getWordsByCategory(breadcrumbs, updateWords, loadedIds).then(() => {
      // UPDATE FILTER
      setDisplayableWords(breadcrumbs);
      setLoading(false);
    });
  }, [breadcrumbs]);

  const capitalize = (s: string) => {
    if (!s) return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const getJLPT = (s: string) => {
    if (!s || s === "") return "N1+";
    return capitalize(s.slice(-2));
  };

  const getCategoryLabel = (key: string) => {
    return (
      <>
        <AllButton
          ghost
          type="primary"
          size="small"
          onClick={(e) => e.stopPropagation()}
        >
          All
        </AllButton>
        <MenuItemLabelText>{key}</MenuItemLabelText>
      </>
    );
  };

  const getItems = () => {
    let topCats = c1.map((cat1: Category) => {
      const { category, sub_categories } = cat1;
      const children = sub_categories.map((cat2name: string) => {
        // console.log({ cat2name });
        const foundCat = c2.find((c2cat: Category) => {
          return c2cat.category === cat2name;
        });
        // console.log({ foundCat });

        let children = foundCat?.sub_categories.map((c3: string) => {
          return {
            key: c3,
            label: c3,
          };
        });

        return {
          key: foundCat?.category,
          label: foundCat?.category,
          children: [
            {
              key: `All ${foundCat?.category}`,
              label: `All ${foundCat?.category}`,
            },
            ...(children ?? []),
          ],
        };
      });
      return {
        key: category,
        label: category,
        children: [
          {
            key: `All ${category}`,
            label: `All ${category}`,
            bc: [],
          },
          ...children,
        ],
        // bc: [cat1.key, cat2.key],
        //   onTitleClick: (e: any) => setBreadcrumbs([cat1.key, cat2.key]),
        // children: [
        //   {
        //     key: `All ${cat2.key}`,
        //     label: `All ${cat2.key}`,
        //     bc: [],
        //   },
        //   ...childern2,
        // ],
      };
    });
    return topCats;
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      {/* <FlashCardModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        tableFilteredData={tableFilteredData}
      /> */}

      <InfoModal />

      <Header style={{ display: "flex", alignItems: "center" }}>
        <Logo style={{ fontSize: 40, color: "white" }}>wordwaku</Logo>
        {/* <img src={"./logo.png"} width="50px" height="50px" /> */}

        {/* <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["2"]}
          //   items={categories.length > 0 && getItems()}
          items={items1}
          style={{ flex: 1, minWidth: 0 }}
        /> */}
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsible
          collapsedWidth={isMobile ? "0" : 50}
          onBreakpoint={(broken) => {
            setIsMobile(broken);
          }}
          //   collapsedWidth={50}
          width={300}
          style={{ background: colorBgContainer }}
        >
          {/* <Button onClick={() => setSiderCollapsed(!siderCollapsed)}>
            TEST
          </Button> */}
          <Menu
            mode="inline"
            defaultSelectedKeys={[]}
            defaultOpenKeys={[]}
            style={{ height: "50px" }}
            // style={{ height: "50%", borderRight: 0 }}
            items={c1 && getItems()}
            onSelect={(e: any) => {
              const revKeyPath = e.keyPath.reverse();
              let bc =
                e.key.slice(0, 3) === "All"
                  ? revKeyPath.slice(0, revKeyPath.length === 2 ? 1 : 2)
                  : revKeyPath;
              console.log({ bc });
              bc = bc.map((item: string) => item.toLowerCase());
              setBreadcrumbs(bc);
            }}
          />
        </Sider>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Breadcrumb
            style={{ margin: "16px 0" }}
            items={breadcrumbs.map((bc: string) => {
              return { title: bc };
            })}
          >
            {/* {breadcrumbs.map((bc: string) => (
              <Breadcrumb.Item>{bc}</Breadcrumb.Item>
            ))} */}
          </Breadcrumb>
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            style={{ width: 200 }}
          />
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 300,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Button onClick={() => console.log({ state })}>TEST</Button>
            <VocabList />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Home;
