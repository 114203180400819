import axios from "axios";
import { config } from "../../consts/url";

export const getC1 = () => {
    return axios({
        // Endpoint to send files
        url: `${config.url}/api/categories/c1`,
        // url: "https://wordwaku-backend.onrender.com/api/categories/c1",
        withCredentials: false,
        method: "GET",
      })
        // Handle the response from backend here
        .then((res) => {
          console.log({ res });
          return res.data.categories_1
        })
  
        // Catch errors if any
        .catch((err) => {
            console.log({err})
        });
}

export const getC2 = () => {
    return axios({
        // Endpoint to send files
        url: `${config.url}/api/categories/c2`,
        // url: "https://wordwaku-backend.onrender.com/api/categories/c2",
        withCredentials: false,
        method: "GET",
      })
        // Handle the response from backend here
        .then((res) => {
          console.log({ res });
          return res.data.categories_2
        })
  
        // Catch errors if any
        .catch((err) => {
            console.log({err})
        });
}