import { useEffect, useState } from "react";

import "./App.css";
import { useC1Store, useC2Store, useStore } from "./state/stores/store";
import Home from "./components/page/home";
import { getC1, getC2 } from "./api/axios/categories";

function App() {
  const updateC1 = useC1Store((state) => state.setCategory);
  const updateC2 = useC2Store((state) => state.setCategory);
  const c1 = useC1Store((state) => state.category);
  const c2 = useC2Store((state) => state.category);

  const { height, width } = useWindowDimensions();
  const setWindow = useStore((state) => state.setWindow);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  useEffect(() => {
    setWindow(width);
  });

  const setC1 = async () => {
    const c1 = await getC1();
    updateC1(c1);
  };

  const setC2 = async () => {
    const c2 = await getC2();
    updateC2(c2);
  };

  useEffect(() => {
    if (c1.length < 1) {
      setC1();
    }
    if (c2.length < 1) {
      setC2();
    }
  });

  return <Home />;
}

export default App;
