import { FC } from "react";
import { Button, List, Modal } from "antd";
import { useStore } from "../../state/stores/store";
import { Sentence } from "../../consts/wordDocument.type";

const SentenceList: FC = (props) => {
  const sentences = useStore((state) => state.selectedWord.sentences);

  //   const data = [
  // {
  //   title: "Reading",
  //   val: selectedWord.word_data.reading,
  // },
  // {
  //   title: "English",
  //   val: renderEng(selectedWord.word_data.english_definitions ?? ""),
  //   //   val: selectedWord._id,
  // },
  // {
  //   title: "Sentences",
  //   //   val: selectedWord.sentences,
  //   val: renderSentences(selectedWord.sentences),
  // },
  // {
  //   title: "Tags",
  //   val: rowData.tags,
  // },
  //   ];

  return (
    <List
      //   style={{ maxHeight: 400, overflowY: "scroll" }}
      itemLayout="horizontal"
      dataSource={sentences}
      renderItem={(item, index) => {
        console.log({ item });
        return (
          <List.Item>
            <List.Item.Meta
              style={{ color: "black" }}
              title={item.japanese}
              description={
                <span style={{ color: "black" }}>{item.en_translation}</span>
              }
            />
          </List.Item>
        );
      }}
    />
  );
};

export default SentenceList;
